.demo-app-calendar {
  width: 100%;
  height: 100%;
  min-width: 1024px;

  .fc {
    //table {
    //    border-collapse: separate;
    //    border-spacing: 0.4rem;
    //}
    .fc-daygrid-day-top {
      flex-direction: row;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fc-scrollgrid-section {
      & > * {
        border: none;
      }

      table {
        width: 100% !important;
        border-collapse: separate;
        border-spacing: 0.5rem;
      }
    }

    .fc-daygrid-day {
      transition: all 0.2s;

      &:not(.fc-day-has-event):not(.fc-day-other) {
        &:hover {
          background-color: #DFEFFF !important;
        }

        &:focus {
          background-color: #A2CFFF !important;
        }

        &:active, &:target {
          background-color: #74B7FF !important;
        }
      }

      //.fc-daygrid-day-events, .fc-daygrid-event-harness {
      *:before, *:after {
        display: none;
      }

      //}
      .fc-daygrid-day-events {
        margin-top: 10px;
        pointer-events: none;
      }

      &.fc-day-today {
        background-color: transparent;
        border: 2px solid #111D5E;
      }
    }
  }

  .fc-scrollgrid-section-header > td {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: none;
  }

  .fc-scroller-harness {
    display: flex;
    align-items: center;
    .fc-scroller-liquid-absolute {
      position: relative;
    }
  }

  .fc-widget-header {
    border: none !important;

    table {
      margin-bottom: 10px;
      //tr {
      //    &:first-child {
      //        border-top-left-radius: 8%;
      //    }
      //    &:last-child {
      //        border-top-right-radius: 8%;
      //    }
      //}
    }
  }

  .fc-day-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111d5e;
    color: white;
    height: 3rem;
    width: 100%;
    border-style: none;
    border-width: 1px;
    vertical-align: top;
  }

  .fc-daygrid {
    .fc-scrollgrid {
      border: none;
    }
  }

  .fc-body > tr > td {
    border: none;
  }

  .fc-col-header {
    th {
      border: none;
      background-color: #111d5e;
      border-radius: 4px;
      height: 40px;
      vertical-align: middle;
      font-size: 1.11rem;
    }
  }

  .fc-col-header-cell-cushion {
    color: white;
  }

  .fc-scrollgrid-sync-table {
    height: 100% !important;
    width: 100% !important;

    td {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: none;

      .fc-daygrid-day-frame {
        min-height: 133px !important;

        &:before {
          display: none;
        }
      }
    }

    .fc-day-other {
      background-color: #F1F2F3 !important;
      box-shadow: none;
    }
  }

  .fc-daygrid-day-top {
    a {
      color: #111D5E;
      font-size: 1.14rem;
      font-weight: bold;
    }

    .fc-daygrid-day-number {
      position: inherit;
    }
  }

  .bgColor {
    background-color: #a2cfff !important;
  }

  .fc-daygrid-event {
    //pointer-events: none;
    font-weight: 500;
    font-size: 1rem;
    background-color: transparent;
    border-color: transparent;
    padding-left: 10px;
    padding-right: 4px;

    .fc-event-main {
      color: #151A30;
    }
  }

  .fc-daygrid-body {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 992px) {
    .W29F2100-total {
      float: left !important;
    }
    .div-prev-next {
      height: 50px !important;
      display: block !important;
      padding-top: 0 !important;
    }
  }
  @media (max-width: 720px) {
    .W29F2100-detail-container {
      width: 100% !important;
    }
  }

  .W29F2100-total {
    float: right;
  }

  .div-prev-next {
    padding-top: 10px;
    height: 110px;
    display: flex;
    align-items: center;
  }
}
